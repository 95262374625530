import React, { useState, useEffect } from "react";
import Link from "gatsby-link";
import Layout from "../../components-pl/layout";
import Login from "../../components-pl/login";
import Register from "../../components-pl/register";
import { isUserLoggedIn, logOut } from "../../utils/functions";
import { isEmpty } from "lodash";
import "./style.scss";
import SEO from "../../components-pl/seo";
import CustomerAccount from "../../components-pl/customer-account";

const MyAccount = () => {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const auth = isUserLoggedIn();
    document.getElementById("lang-switch").href = "/my-account/";
    //console.log(auth)
    if (!isEmpty(auth)) {
      setLoggedIn(true);
    }
  }, [loggedIn]);

  const handleLogout = () => {
    logOut();
    setLoggedIn(false);
  };

  return (
    <>
      <SEO title={"Moje konto"} header={{ siteTitle: " Shroom | Sklep" }} />
      <Layout>
        <div className="page-container" style={{ textAlign: "center" }}>
          <h1 className="mt-5 woo-next-cart-heading spec-h">Moje konto</h1>
          <p style={{ maxWidth: "80%", marginLeft: "10%" }}>
            Witamy na stronie <b>moje konto</b>, możesz zalogować się na swoje
            konto, a następnie zarządzać swoimi zamówieniami oraz danymi i
            szczegółami konta.
            <br />
            <br />
            {!loggedIn ? (
              <span className="py-5">
                Nie masz konta?{" "}
                <b className="text-success">
                  Zarejestruj się teraz i uzyskaj niesamowite oferty!
                </b>
              </span>
            ) : (
              <span className="py-5">
                Masz pytania dotyczące swojego konta?{" "}
                <Link className="text-primary" to="/pl/kontakt/">
                  Skontaktuj się z nami
                </Link>
                .
              </span>
            )}
          </p>
        </div>
        {loggedIn ? (
          <div className="account-details container py-5">
            <CustomerAccount
              setLoggedIn={setLoggedIn}
              handleLogout={handleLogout}
            />
          </div>
        ) : (
          <div
            className="page-container container py-5"
            style={{ marginTop: "-50px" }}
          >
            <div className="row" style={{ justifyContent: "center" }}>
              <Login setLoggedIn={setLoggedIn} />
              <Register setLoggedIn={setLoggedIn} />
            </div>
          </div>
        )}
      </Layout>
    </>
  );
};

export default MyAccount;
